<template>
  <div style="padding:1px">
    <div class="container">
      <!-- 头部导航  -->
      <downPageTopmb />
    </div>
    <div class="content-box">
      <el-image :src="contentList" width="100%" style="width: 100%;" @load="imgOnload" lazy>
        <div slot="placeholder" class="image-slot">
          {{$t('加载中')}}<span class="dot">...</span>
        </div>
      </el-image>
      <div class="button-box">
        <el-image v-if="showBtn"  @click="openUrl('https://v.qq.com/biu/creator/home?vcuid=9004247012')" :src="moreDownlaodBtn" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <el-image v-if="showBtn"  :src="WX_applet" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <!-- <a href="/tcappNo108V183.apk" class="android">
          <el-image v-if="showBtn" :src="androidDownlaodBtn" width="100%" lazy>
            <div slot="placeholder" class="image-slot">
              {{$t('加载中')}}<span class="dot">...</span>
            </div>
          </el-image>
        </a> -->
        <!-- <a href="https://v.qq.com/biu/creator/home?vcuid=9004247012" target="_blank">
              <img :src="moreDownlaodBtn" alt="" class="WX_applet" style="margin-right: 20px;">
        </a> -->
        <!-- <img v-if="showBtn" class="WX_applet" :src="WX_applet" alt=""> -->
      </div>
    </div>
    <!-- <div v-show="language==='ja'">
      ダウンロードは保留中
    </div> -->

  </div>
</template>
<script>
import downPageTopmb from "@/components/downPage-top-mb";
export default {
  data() {
    return {
      contentList: require("@/assets/images/downloadPage/B/CH_B.jpg"),
      showBtn: false,
      appleDownlaodBtn: require("@/assets/images/downloadPage/B/button/MB_apple.png"),
      androidDownlaodBtn: require("@/assets/images/downloadPage/B/button/MB_android.png"),
      WX_applet: require("@/assets/images/downloadPage/wechat_pc.png"),
      moreDownlaodBtn: require("@/assets/images/downloadPage/B/button/MB_more.png"),
    };
  },
  mounted() {
    // 进入页面回到顶部
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")];
    },
    language() {
      return localStorage.getItem("locale") || "zh_tw";
    },
  },
  methods: {
    openUrl(url) {
      window.open(url);
    },
    imgOnload() {
      this.showBtn = true;
      this.$forceUpdate();
    },
  },
  components: {
    downPageTopmb,
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  // padding: 0 16px;
  // box-sizing: border-box;
}
.content-box {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 10px;
}
.button-box {
  width: 100%;
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  .android,
  .app {
    display: block;
    width: 42%;
    margin: 0 5px;
  }

  .el-image{
    width: 42%;
  }
}
.button_Enbox {
  position: absolute;
  bottom: 4%;
  left: 8%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  .google,
  .app,
  .more {
    display: block;
    width: 42%;
    margin: 0 5px;
  }
}
</style>